<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row >
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Seguimiento Campañas Vacantes</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterSeguimiento  , 'SEGUIMIENTO_VAC')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
              v-if="[72,11].includes( getdatosUsuario.idpuesto )"
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
              <v-col cols="12" md="3" lg="2">
	  		  			<v-text-field label="Fecha Inicial" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaIni"
                ></v-text-field>
	  		  		</v-col>
              <v-col cols="12" md="3" lg="2">
	  		  			<v-text-field label="Fecha Final" 
                  type="date" 
                  filled 
                  dense 
                  hide-details 
                  v-model="filtroFechaFin"
                ></v-text-field>
	  		  		</v-col>
              <v-col cols="12" md="6" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="id_puesto"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-autocomplete
                  :items="planteles"
                  item-text="plantel"
                  item-value="id_plantel"
                  v-model="id_plantel"
                  label="Plantel"
                  clearable
                  filled
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-spacer></v-spacer>
	  		  	</v-row>

            <v-row>
              <v-col cols="12" class="black--text mb-0 pb-0">
                <b>Vacantes Activas: </b>{{ vacantes.filter( el=> el.estatus == 1 ).length }}<br/>
                <b>Vacantes Inactivas: </b>{{ vacantes.filter( el=> el.estatus == 0 ).length }}<br/>
                <b>Vacantes: </b>{{ vacantes.length }}<br/>
                <b>Contratados: </b>{{  vacantes.map(item => item.contratados).reduce((prev, curr) => prev + curr, 0) }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-radio-group
                  v-model="opEstatus"
                  row
                  hide-details
                >
                  <v-radio
                    label="Activos"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Inactivos"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3">
                
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterSeguimiento"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
                  item-class="clase_row"
							  >

                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="green" v-if="item.estatus == 1" small dark >Activo</v-chip>
                    <v-chip color="red"   v-if="item.estatus == 0" small dark >Inactivo</v-chip>
                  </template>

                  <template v-slot:item.sexo="{ item }">
                    <v-chip color="blue" v-if="item.sexo == 1" small dark >Ind.</v-chip>
                    <v-chip color="pink"   v-if="item.sexo == 2" small dark >F</v-chip>
                  </template>

                  <template v-slot:item.funciona="{ item }">
                    <v-chip color="green" v-if="item.funciona == 1" small dark >Si</v-chip>
                    <v-chip color="red"   v-if="item.funciona == 0" small dark >No</v-chip>
                  </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
                			v-if="[71,72,64,68,69,76,77,63,31,11].includes( getdatosUsuario.idpuesto )"
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small

							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idseguimiento_campanias }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12" md="6">
              <v-autocomplete
                label="Sucursal"
                filled
                dense
                hide-details
                v-model="editedItem.id_plantel"
                :items="planteles"
                item-text="plantel"
                item-value="id_plantel"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="puestosAll"
                v-model="editedItem.id_puesto"
                label="Puesto"
                clearable
                filled
                dense
                item-text="puesto"
                item-value="idpuesto"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                label="Elige un turno"
                filled
                dense
                hide-details
                v-model="editedItem.id_turno"
                :items="turnos"
                item-text="turno"
                item-value="idturnos"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.cantidad"
                label="cantidad"
                filled
                hide-details
                dense
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="editedIndex > -1 && [71,72,64,68,69,76,77,63,31,11].includes( getdatosUsuario.idpuesto )">
              <v-autocomplete
                :items="puestosAll"
                v-model="editedItem.id_puesto"
                label="Puesto"
                clearable
                filled
                dense
                item-text="puesto"
                item-value="idpuesto"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="[71,72,64,68,69,76,77,63,31,11].includes( getdatosUsuario.idpuesto )">

              <v-checkbox
                v-model="editedItem.sexo_i"
                label="Indistinto"
                dense
                v-if="editedItem.id_puesto == 10 "
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-model="editedItem.sexo_f"
                label="Femenino"
                dense
                v-if="editedItem.id_puesto == 10 "
                hide-details
              ></v-checkbox>
              <!-- <v-radio-group
                v-if="editedItem.id_puesto == 10 "
                v-model="editedItem.sexo"
                row
              >
                <v-radio
                  label="Indistinto"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="Femenino"
                  :value="2"
                ></v-radio>
              </v-radio-group> -->
            </v-col>

            <v-col cols="12" md="6" v-if="[71,72,64,68,69,76,77,63,31,11].includes( getdatosUsuario.idpuesto )">
              <v-text-field
                v-model="editedItem.fecha_apertura"
                label="Fecha apertura"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>


            <v-col cols="12" v-if="editedIndex > -1 && [25,48,49,47,74,62,54,70,75,11].includes( getdatosUsuario.idpuesto )">
              <v-text-field
                v-model="editedItem.campania"
                label="Campaña"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>


            <v-col cols="12" v-if="editedIndex > -1">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    filled
                    hide-details
                    dense
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="resultado"
                    label="Resultado"
                    filled
                    hide-details
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn small dark color="primary" @click="saveResultado()" class="my-2">Agregar</v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headersMkt"
                :items="detalles"
                class="elevation-0 mt-4"
                :search="search"
                :mobile-breakpoint="100"
                dense
              >

                <template v-slot:item.actions="{ item }">
                  <v-btn 
                    color="error" 
                    x-small
                    @click="deleteItem2(item)"
                    class="mr-2"
                  >
                    <v-icon small> mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="editedItem.estatus"
                label="Activo"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-if="editedIndex > -1"
                v-model="editedItem.funciona"
                label="Funciona?"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      id_puesto:0,

      editedItem: {
        idseguimiento_campanias: 0,
				id_sucursal: 0,
				id_puesto: 0,
				id_turno: 0,
				id_coordinadora: 0,
				campania: '',
				resultado_mkt: 0,
				resultado_rh: 0,
				funciona: 0,
				deleted:0,
				fecha_creacion:null,
        estatus: 1,
        sexo:0,
        cantidad:0,
        fecha_apertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },

      defaultItem: {
        idseguimiento_campanias: 0,
				id_sucursal: 0,
				id_puesto: 0,
				id_turno: 0,
				id_coordinadora: 0,
				campania: '',
				resultado_mkt: 0,
				resultado_rh: 0,
				funciona: 0,
				deleted:0,
				fecha_creacion:null,
        estatus: 1,
        sexo:0,
        cantidad:0,
        fecha_apertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },

      // Filtros: 
      incio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fin  :(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      salones: [],
      headers: [
        { text: 'ID'                   , value: 'idseguimiento_campanias'  },
        { text: 'Plantel  '            , value: 'plantel'                  },
        { text: 'Puesto'               , value: 'puesto'                   },
        { text: 'Turno'                , value: 'turno'                    },
        { text: 'Coordinadora'         , value: 'nombre_completo'          },
        { text: 'Campaña'              , value: 'campania'                 },
        { text: 'Res. MKT'             , value: 'resultado_mkt'            },
        { text: 'Res. RH'              , value: 'resultado_rh'             },
        { text: 'Panel Entrevistas	'  , value: 'panel_entrevistas'        },
        { text: 'Entre. Asistieron'    , value: 'entrevistas_asistieron'   },
        { text: 'Contratados'          , value: 'contratados'              },
        { text: '¿funciona?'           , value: 'funciona'                 },
        { text: 'Estatus'              , value: 'estatus'                  },
        { text: 'Días espera'          , value: 'dias_sin_cubrir'          },
        { text: 'Fecha'                , value: 'fecha_apertura'           },
        { text: 'Sexo'                 , value: 'sexo'                     },
        { text: 'Cantidad'             , value: 'cantidad'                 },
        { text: 'Actions'              , value: 'actions', sortable: false },
      ],

      vacantes: [],
      puestos: [],
      turnos:[],
      planteles:[],
      filtroEstatus: 0,
      filtroFechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filtroFechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      opEstatus: 1,


      headersMkt: [
        { text: 'Fecha'                , value: 'fecha'                  },
        { text: 'Resultado'            , value: 'valor'                   },
        { text: 'Actions'              , value: 'actions', sortable: false },
      ],
      detalles:[],
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      resultado: 0,
      id_plantel:0,
      puestosAll:[],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar vacante' : 'Editar vacante'
      },

      filterSeguimiento(){

        let data = this.vacantes

        if( [0,1].includes( this.opEstatus ) ){
          data = data.filter( el => el.estatus == this.opEstatus )
        }

        if( this.id_puesto ){
          data = data.filter( el => el.id_puesto == this.id_puesto )
        }

        if( this.id_plantel ){
          data = data.filter( el => el.id_plantel == this.id_plantel )
        }

        return data 
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
      await this.getTurnos()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.vacantes = []
        
        const payload = {
        	fechaini: this.filtroFechaIni,
					fechafin: this.filtroFechaFin
        }

        return this.$http.post('seguimiento.vacantes', payload ).then(response=>{
        	this.vacantes = response.data

          this.puestos = this.vacantes.map((registo) => { return { idpuesto: registo.id_puesto, puesto: registo.puesto } })
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestosAll = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false

          this.puestosAll = response.data 
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTurnos( ){
        this.cargar = true
        this.turnos = []
        this.$http.get('seguimiento.turnos').then(response=>{
          this.turnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      editItem (item) {
        this.editedIndex = this.vacantes.indexOf(item)
        this.editedItem = Object.assign({}, item)

        let tipo = 2
        if( [71,72,64,68,69,76,77,63,31].includes( this.getdatosUsuario.idpuesto ) ){ tipo = 1 }

        let payload = {
          idseguimiento_campanias: this.editedItem.idseguimiento_campanias, 
          tipo
        }

        // Lo mandapos por el EP
        this.$http.post('seguimiento.vacantes.detalle', payload).then(response=>{
          this.detalles = response.data
          this.dialog = true
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      deleteItem (item) {
        this.editedIndex = this.vacantes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItem2 (item) {

        console.log( item )
        item.deleted = 1

        this.cargar = true
        // Lo mandapos por el EP

        this.$http.put('seguimiento.vacantes.detalle.put/' + item.idseguimiento_campanias_detalle, item).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1

        this.$http.put('seguimiento.vacantes.update/' + this.editedItem.idseguimiento_campanias, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if( !this.editedItem.id_turno || !this.editedItem.id_plantel || !this.editedItem.id_puesto ){
      		return this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('seguimiento.vacantes.update/' + this.editedItem.idseguimiento_campanias, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('seguimiento.vacantes.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      saveResultado( ){

        let tipo = 2
        if( [71,72,64,68,69,76,77,63,31].includes( this.getdatosUsuario.idpuesto ) ){ tipo = 1 }

        let payload = {
          fecha     : this.fecha,
          valor     : this.resultado,
          rh_mkt    : tipo,
          id_usuario: this.getdatosUsuario.iderp,
          idseguimiento_campanias: this.editedItem.idseguimiento_campanias
        }

        let valor = Object.assign({}, this.editedItem )

        // Lo mandapos por el EP
        this.$http.post('seguimiento.vacantes.detalle.add', payload).then(response=>{
          this.resultado = 0
          this.close( )
          this.initialize( )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }
    },
  }
</script>
falta_campania

<style>
  .falta_campania{
    background-color: #FF8F8F !important;
    color: white;
  }
</style>

